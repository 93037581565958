<template>
    <router-link
        :to="path"
        class="inline-link"
        v-if="!back && !external && !hash"
    >
        <span class="inner">
            {{ title }}
            <i class="icon arrow"></i>
        </span>
    </router-link>

    <a
        :href="path"
        target="_blank"
        rel="noopener"
        class="inline-link"
        v-else-if="external"
    >
        <span class="inner">
            {{ title }}
            <i class="icon arrow"></i>
        </span>
    </a>

    <a :href="path" class="inline-link" v-else-if="hash" @click="scrollTo">
        <span class="inner">
            {{ title }}
            <i class="icon arrow"></i>
        </span>
    </a>
    <router-link :to="path" class="inline-link back" v-else>
        <span class="inner">
            <i class="icon arrow"></i>
            <span class="text">
                {{ title }}
            </span>
        </span>
    </router-link>
</template>

<script>
export default {
    props: {
        title: { default: "View" },
        path: { default: "#!" },
        back: Boolean,
        external: Boolean,
        hash: Boolean,
        offset: { default: 150 }
    },
    methods: {
        scrollTo(e) {
            e.preventDefault();
            let el = document.getElementById(this.path.split("#")[1]);
            const y =
                el.getBoundingClientRect().top +
                window.pageYOffset -
                this.offset;
            window.scrollTo({ top: y, behavior: "smooth" });
        }
    }
};
</script>

<style lang="scss">
a.inline-link {
    font-size: 18px;
    position: relative;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    .inner {
        display: flex;
        align-items: center;
    }

    .arrow {
        margin-left: 0.5rem;
        width: 34px;
        height: 12px;
        transition: margin 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }

    &:hover {
        .arrow {
            margin-left: 1rem;
            margin-right: 0.5rem;
        }
    }

    &.back {
        .arrow {
            margin: 0 0.5rem 0 0;
            transform: rotate(180deg);
        }

        &:hover {
            .arrow {
                margin-right: 1rem;
                margin-left: 0.5rem;
            }
        }
    }

    @media (max-width: 700px) {
        font-size: 16px;
    }
}
</style>
