import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import title from "./mixins/title";
import lang from "./mixins/lang";
import store from "./store";
import VueMeta from "vue-meta";
import VueModal from "vue-js-modal";
import VueRellax from "vue-rellax";
import VueScrollactive from 'vue-scrollactive';

// Set active in header
Vue.use(VueScrollactive);

// Vue parallax
Vue.use(VueRellax);

// SEO meta tags
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

// Vue modal
Vue.use(VueModal);

// Vue mixins
Vue.mixin(title);
Vue.mixin(lang);

// Global variables
Vue.prototype.$apiroute = process.env.VUE_APP_API_ROUTE;

// Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount("#app");
