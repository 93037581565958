import axios from "axios";

function setCookie(name, value) {
    let now = new Date();
    let time = now.getTime();
    time += 3600 * 1000;
    now.setTime(time);
    document.cookie = `${name}=${value}; expires=${now.toUTCString()}; path=/`;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
        return parts
            .pop()
            .split(";")
            .shift();
}

function clearCookie(name) {
    if (getCookie(name)) {
        document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        return true;
    }
    return false;
}

async function logOut(next) {
    const token = getCookie("token");

    try {
        let response = await axios.post(
            `${process.env.VUE_APP_API_ROUTE}/auth/logout`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        if (response && response.status == 200 && clearCookie("token")) {
            next({ path: "/" });
        }
    } catch (error) {
        next({ path: "/error", query: { message: error.message } });
    }
}

export { setCookie, getCookie, clearCookie, logOut };
