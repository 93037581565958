<template>
    <footer id="contact">
        <div class="contact-info">
            <h2>{{ $t("get-in-touch.title") }}</h2>
            <p>
                <a class="mail" :href="emailPrefix + emailDecoded">{{
                    emailDecoded
                }}</a>
                <span class="hyphen">-</span
                ><a
                    href="https://www.google.com/maps/place/Sint-Martens-Latem/@51.0086338,3.5880607,13z/data=!3m1!4b1!4m5!3m4!1s0x47c36d8ff5d3ff61:0x40099ab2f4d5c80!8m2!3d51.0089664!4d3.6330725?hl=es"
                    rel="noopener"
                    target="_blank"
                    >{{ location }}</a
                >
            </p>
            <ul class="socials">
                <li v-if="socials.instagram">
                    <a :href="socials.instagram" target="_blank" rel="noopener"
                        >Instagram</a
                    >
                </li>
                <li v-if="socials.facebook">
                    <a :href="socials.facebook" target="_blank" rel="noopener"
                        >Facebook</a
                    >
                </li>
                <li v-if="socials.linkedin">
                    <a :href="socials.linkedin" target="_blank" rel="noopener"
                        >LinkedIn</a
                    >
                </li>
            </ul>
        </div>

        <div class="footer-end">
            <ul>
                <li class="copy">
                    &copy; {{ company }} {{ new Date().getFullYear() }} |
                    <router-link to="/privacy">{{
                        $t("general.copy")
                    }}</router-link>
                    |
                    <select
                        v-model="$root.$i18n.locale"
                        @change="setLanguage()"
                    >
                        <option value="nl" selected>NL</option>
                        <option value="en">EN</option>
                    </select>
                </li>
                <li class="dev">
                    {{ $t("get-in-touch.developer") }}
                    <a href="https://overnout.be" rel="noopener" target="_blank"
                        >ONO</a
                    >
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            socials: {
                facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
                instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
                twitter: process.env.VUE_APP_SOCIAL_TWITTER,
                linkedin: process.env.VUE_APP_SOCIAL_LINKEDIN
            },
            company: process.env.VUE_APP_COMPANY_NAME,
            location: process.env.VUE_APP_COMPANY_CITY,
            emailDecoded: atob(process.env.VUE_APP_EMAIL_BASE64),
            emailPrefix: atob("bWFpbHRvOg==")
        };
    },
    methods: {
        setLanguage() {
            localStorage.setItem("lang", this.$root.$i18n.locale);
        }
    }
};
</script>

<style lang="scss">
footer {
    background-color: $washed-red;
    flex-shrink: 0;
    font-family: "Titillium Web", sans-serif;
    text-align: center;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .contact-info {
        margin-top: 30vh;

        h2 {
            font-family: "acumin-pro", serif;
            font-family: "Nelphim", serif;
            font-weight: normal;
            color: $maroon;
        }

        .mail {
            text-decoration: underline;
        }

        .hyphen {
            padding: 1rem;
            display: inline-block;
        }

        @media (max-width: 900px) {
            .hyphen {
                display: block;
                padding: 0;
            }
        }

        ul {
            margin-top: 10rem;

            li {
                margin-bottom: 1rem;
            }
        }
    }

    .footer-end {
        color: $grey;
        width: 100%;

        ul {
            font-size: 16px;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            display: flex;
            padding: 2rem;

            select {
                border: none;
                background: transparent;
                font-family: "Titillium Web";
                padding-right: 0.2rem;
            }

            .copy a {
                color: $grey;
            }

            .dev a {
                text-decoration: underline;
            }

            @media (max-width: 900px) {
                align-items: center;
                flex-direction: column !important;
            }
        }
    }

    a:hover {
        color: $red;
    }
}
</style>
