<template
    ><div class="burger-menu">
        <div @click="closeMenu()" class="burger-close">
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
        <div class="links">
            <scrollactive active-class="router-link-active" :offset="0">
                <h2 class="hidden">{{ $t("nav.mobile-menu") }}</h2>
                <ul>
                    <li>
                        <a
                            href="#home"
                            v-if="$route.name === 'Home'"
                            data-section-selector=".hero"
                            class="scrollactive-item router-link-active"
                            @click="closeMenu()"
                        >
                            {{ $t("nav.home") }}
                        </a>
                        <router-link
                            v-else
                            to="/"
                            exact
                            @click.native="closeMenu()"
                            class="menu-link menu-home"
                        >
                            {{ $t("nav.home") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            to="/portfolio"
                            @click.native="closeMenu()"
                        >
                            {{ $t("nav.portfolio") }}
                        </router-link>
                    </li>
                    <li>
                        <a
                            v-if="$route.name === 'Home'"
                            href="#workflow"
                            class="scrollactive-item"
                            @click="closeMenu()"
                        >
                            {{ $t("nav.workflow") }}
                        </a>
                        <router-link
                            v-else
                            to="/#workflow"
                            @click.native="closeMenu()"
                            class="menu-link menu-workflow"
                        >
                            {{ $t("nav.workflow") }}
                        </router-link>
                    </li>
                    <li>
                        <a
                            v-if="$route.name === 'Home'"
                            href="#quote"
                            class="scrollactive-item"
                            @click="closeMenu()"
                        >
                            {{ $t("nav.pricing") }}
                        </a>
                        <router-link
                            v-else
                            to="/#quote"
                            @click.native="closeMenu()"
                            class="menu-link menu-pricing"
                        >
                            {{ $t("nav.pricing") }}
                        </router-link>
                    </li>
                    <li>
                        <a
                            v-if="$route.name === 'Home'"
                            href="#about"
                            class="scrollactive-item"
                            @click="closeMenu()"
                        >
                            {{ $t("nav.about") }}
                        </a>
                        <router-link
                            v-else
                            to="/#about"
                            @click.native="closeMenu()"
                            class="menu-link menu-about"
                        >
                            {{ $t("nav.about") }}
                        </router-link>
                    </li>
                    <li>
                        <select
                            v-model="$root.$i18n.locale"
                            @change="setLanguage()"
                        >
                            <option value="nl" selected>nl</option>
                            <option value="en">en</option>
                        </select>
                    </li>
                </ul>
            </scrollactive>
            <div>
                <a
                    v-if="$route.name === 'Home'"
                    href="#contact"
                    class="catch btn maroon-bg scrollactive-item"
                    @click="getCookie('token') ? logout($event) : closeMenu()"
                >
                    <span class="inner">
                        {{
                            getCookie("token")
                                ? $t("nav.log-out")
                                : $t("nav.get-in-touch")
                        }}
                        <i class="icon arrow arrow-white"></i>
                    </span>
                </a>
                <router-link
                    v-else
                    to="#contact"
                    class="btn maroon-bg"
                    @click.native="
                        getCookie('token') ? logout($event) : closeMenu()
                    "
                >
                    <span class="inner">
                        {{
                            getCookie("token")
                                ? $t("nav.log-out")
                                : $t("nav.get-in-touch")
                        }}
                        <i class="icon arrow arrow-white"></i>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookie } from "@/helpers";

export default {
    methods: {
        getCookie: getCookie,
        closeMenu() {
            document
                .querySelector(".burger-menu")
                .classList.remove("animate-menu");
            document.querySelector("body").style.overflowY = "auto";
        },
        setLanguage() {
            localStorage.setItem("lang", this.$root.$i18n.locale);
        },
        logout(event) {
            event.preventDefault();
            this.closeMenu();
            this.$emit("loading", this.$t("logout.text"));
            window.location.href = "/logout";
        }
    }
};
</script>

<style lang="scss" scoped>
.burger-menu {
    z-index: 102;
    // transform: translateY(-100%);
    top: -100%;
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    // top: 0;
    overflow: hidden;
    transition: top 0.3s ease-out 0.1s;

    &.animate-menu {
        // transform: translateY(0%);
        top: 0;
    }

    .burger-close {
        cursor: pointer;
        display: flex;
        height: 4rem;
        width: 2rem;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 1rem;
        top: 0;

        .bar {
            background-color: $black;
            width: 30px;
            height: 2px;
            position: absolute;

            &:first-of-type {
                transform: rotate(45deg);
            }

            &:last-of-type {
                transform: rotate(-45deg);
            }
        }
    }

    .links {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        ul {
            padding: 2rem;
            font-size: 36px;

            select {
                border: none;
                background-color: transparent;
                cursor: pointer;
                font-size: 24px;
                margin-left: -3px;
                padding-right: 0.5rem;

                &:focus {
                    // outline: none;
                    outline: 2px solid $black;
                }
            }
        }

        div {
            padding: 0 2rem;

            a.btn {
                margin: 0;
                width: 100%;

                height: 3.8rem;

                .inner {
                    display: flex;
                    align-items: center;
                }

                .icon {
                    width: 34px;
                    height: 12px;
                    margin-left: 0.5rem;
                    margin-top: 1px;
                    transition: margin 0.1s linear;
                }

                &:hover .icon {
                    margin-left: 1rem;
                }
            }
        }
    }

    @media (min-width: 1320px) {
        display: none;
    }
}
</style>
