import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        projects: null,
        prices: null,
        api_route: process.env.VUE_APP_API_ROUTE
    },
    getters: {
        // Projects
        allProjects: state => state.projects,
        getProjectById: state => id => {
            return state.projects.find(proj => proj.id === parseInt(id));
        },
        getPrevProject: state => id => {
            const currentproject = state.projects.find(proj => proj.id == id);
            const currentCategory = state.projects.filter(
                proj => proj.category == currentproject.category
            );
            let currentIndex = currentCategory.findIndex(
                proj => proj.id == currentproject.id
            );
            let prevIndex = -1;

            if (currentIndex == 0) {
                prevIndex = currentCategory.length - 1;
            } else {
                prevIndex = currentIndex - 1;
            }
            return currentCategory[prevIndex];
        },
        getNextProject: state => id => {
            const currentproject = state.projects.find(proj => proj.id == id);
            const currentCategory = state.projects.filter(
                proj => proj.category == currentproject.category
            );
            let currentIndex = currentCategory.findIndex(
                proj => proj.id == currentproject.id
            );
            let nextIndex = -1;
            if (currentIndex == currentCategory.length - 1) {
                nextIndex = 0;
            } else {
                nextIndex = currentIndex + 1;
            }
            return currentCategory[nextIndex];
        },
        // Pricing
        allPrices: state => state.prices
    },
    actions: {
        getProjects({ state, commit }) {
            axios.get(`${state.api_route}/projects`).then(response => {
                commit("SET_PROJECTS", response.data.projects);
            });
        },
        getPrices({ state, commit }) {
            axios.get(`${state.api_route}/prices`).then(response => {
                commit("SET_PRICES", response.data.prices);
            });
        }
    },
    mutations: {
        SET_PROJECTS(state, projects) {
            state.projects = projects;
        },
        SET_PRICES(state, prices) {
            state.prices = prices;
        }
    },
    modules: {}
});
