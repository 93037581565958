<template>
    <div class="loader">
        <p v-if="message">{{ message }}</p>
        <div class="circles">
            <div class="circle maroon"></div>
            <div class="circle red"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: { type: String, default: null }
    }
};
</script>

<style lang="scss" scoped>
$size: 45px;

.loader {
    z-index: 99999;
    height: 100%;
    width: 100%;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);

    p {
        font-size: 18px;
        font-family: "Titillium Web", sans-serif;
        font-weight: bold;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center !important;
    }

    .circles {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circle {
        width: 4rem;
        height: 2rem;

        border-top-left-radius: $size * 2;
        border-top-right-radius: $size * 2;

        &.red {
            background: $red;
            // animation: 2s ease-in-out infinite rotate-back;
            animation: 2s cubic-bezier(0.4, 0, 0.2, 1) infinite rotate-back;
        }

        &.maroon {
            top: 0;
            position: absolute;
            background: $maroon;
            animation: 2s ease-in-out infinite rotate;
        }

        display: inline-block;

        transform-origin: 50% 100%;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes rotate-back {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
}
</style>
