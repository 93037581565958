<template>
    <div class="home content">
        <main>
            <!-- Hero -->
            <section class="hero" id="home">
                <div
                    class="hero-img-2-wrapper rellax"
                    v-rellax="{ speed: -1, center: true }"
                >
                    <h2 class="title" v-html="$t('hero.title')"></h2>
                    <div class="img-container hero-img-2">
                        <img
                            data-image="Home 1.jpg"
                            src="@/assets/images/home/Home 1 small.jpg"
                            title="Living space render"
                            class="asyncImage"
                        />
                    </div>
                </div>
                <div class="quote">
                    <p v-html="$t('hero.quote')"></p>
                    <Link
                        class="catch"
                        :title="$t('hero.catch')"
                        :hash="true"
                        path="#portfolio"
                        :offset="0"
                    />
                </div>

                <div
                    class="img-container hero-img-1 rellax"
                    v-rellax="{ speed: -1, center: true }"
                >
                    <img
                        data-image="Home 12.jpg"
                        src="@/assets/images/home/Home 12 small.jpg"
                        title="Rendered cabinet"
                        alt="Image of a rendered cabinet inset into a wall"
                        class="asyncImage"
                    />
                </div>

                <div
                    class="img-container hero-img-3 rellax"
                    v-rellax="{ speed: -0.5, center: true }"
                >
                    <img
                        data-image="Home 15.jpg"
                        src="@/assets/images/home/Home 15 small.jpg"
                        title="Changing room render"
                        alt="Black and white render of a changing room"
                        class="asyncImage"
                    />
                </div>

                <div class="img-container hero-img-4 rellax">
                    <!-- v-rellax="{ speed: -1, center: true }" -->
                    <img
                        data-image="Home 14.jpg"
                        src="@/assets/images/home/Home 14 small.jpg"
                        title="Rendered cabinet"
                        alt="Image of a rendered cabinet inset into a wall"
                        class="asyncImage"
                    />
                </div>
            </section>

            <hr />

            <!-- Portfolio -->
            <section id="portfolio">
                <div
                    class="portfolio-img-1-wrapper rellax"
                    v-rellax="{ speed: -1, center: true }"
                >
                    <h2 class="title" v-html="$t('portfolio.title')"></h2>

                    <div class="img-container portfolio-img-2">
                        <img
                            data-image="Home 7.png"
                            src="@/assets/images/home/Home 7 small.jpg"
                            title="Inset cabinet visual"
                            alt="Visualization of a cupboard inset into a wall"
                            class="asyncImage"
                        />
                    </div>
                </div>

                <div
                    class="img-container portfolio-img-1 rellax"
                    v-rellax="{ center: true, speed: -1 }"
                >
                    <img
                        data-image="Home 13.jpg"
                        src="@/assets/images/home/Home 13 small.jpg"
                        title="Living room render"
                        alt="Image of a stylish living space with big window"
                        class="asyncImage"
                    />
                </div>

                <div
                    class="img-container portfolio-img-3 rellax"
                    v-rellax="{ center: true, speed: -1 }"
                >
                    <img
                        data-image="Home 6.jpg"
                        src="@/assets/images/home/Home 6 small.jpg"
                        title="3D kitchen model"
                        alt="Picture of a rendered kitchen counter"
                        class="asyncImage"
                    />
                </div>

                <div
                    :class="
                        `quote quote-1 ${
                            $i18n.locale == 'en' ? '' : 'center-content'
                        }`
                    "
                >
                    <p v-if="$i18n.locale == 'en'">
                        {{ $t("portfolio.quote-1-1") }}<br />
                        {{ $t("portfolio.quote-1-2") }}
                    </p>
                    <ul>
                        <li>
                            {{ $t("portfolio.interior") }}
                        </li>
                        <li>
                            {{ $t("portfolio.exterior") }}
                        </li>
                        <li>
                            {{ $t("portfolio.floorplan") }}
                        </li>
                    </ul>
                    <Link
                        class="catch"
                        :title="$t('portfolio.catch-1')"
                        path="/portfolio"
                    />
                </div>

                <!-- Floorplan -->
                <div class="img-container portfolio-img-4">
                    <img
                        data-image="Home 10.png"
                        src="@/assets/images/home/Home 10 small.png"
                        title="2D image of a floorplan"
                        alt="Image of the first floor of a building as a 2D drawing"
                        class="asyncImage"
                    />
                </div>

                <div class="quote quote-2">
                    <p v-html="$t('portfolio.quote-2')"></p>
                    <Link
                        class="catch"
                        :title="$t('portfolio.catch-2')"
                        :hash="true"
                        :offset="0"
                        path="#workflow"
                    />
                </div>

                <!-- Floorplan -->
                <div
                    class="img-container portfolio-img-5 rellax"
                    v-rellax="{ center: true, speed: -1 }"
                >
                    <img
                        data-image="Home 11.png"
                        src="@/assets/images/home/Home 11 small.png"
                        title="Intersection of a floorplan"
                        alt="Image of the intersection of the first floor of a building"
                        class="asyncImage"
                    />
                </div>
                <!-- <div class="bg"></div> -->
            </section>

            <hr />

            <!-- Workflow -->
            <section id="workflow">
                <div class="workflow-img-wrapper">
                    <h2 class="title">{{ $t("workflow.title") }}</h2>
                    <div class="img-container workflow-img">
                        <img
                            data-image="Home 2.jpg"
                            src="@/assets/images/home/Home 2 small.jpg"
                            title="3D model of a couch"
                            alt="Image of a sofa rendered in 3D in a living area"
                            class="asyncImage"
                        />
                    </div>
                </div>

                <div class="text">
                    <div class="numbers">
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                        <p>4</p>
                        <p>5</p>
                    </div>
                    <div class="step">
                        <div class="step-number">
                            1
                        </div>
                        <div>
                            <h3>
                                {{ $t("workflow.step-1-1") }}{{ " "
                                }}<a
                                    class="underline"
                                    href="#quote"
                                    @click="scrollTo($event, '#quote')"
                                    >{{ $t("workflow.step-1-catch") }}</a
                                >{{ " " }}{{ $t("workflow.step-1-2") }}
                            </h3>
                        </div>
                    </div>
                    <div class="step">
                        <div class="step-number">
                            2
                        </div>
                        <div>
                            <h3 v-html="$t('workflow.step-2')"></h3>
                            <p v-html="$t('workflow.text-2')"></p>
                        </div>
                    </div>
                    <div class="step">
                        <div class="step-number">
                            3
                        </div>
                        <div>
                            <h3 v-html="$t('workflow.step-3')"></h3>
                            <p v-html="$t('workflow.text-3')"></p>
                        </div>
                    </div>
                    <div class="step">
                        <div class="step-number">
                            4
                        </div>
                        <div>
                            <h3 v-html="$t('workflow.step-4')"></h3>
                            <p v-html="$t('workflow.text-4')"></p>
                        </div>
                    </div>
                    <div class="step">
                        <div class="step-number">
                            5
                        </div>
                        <div>
                            <h3 v-html="$t('workflow.step-5')"></h3>
                            <p v-html="$t('workflow.text-5')"></p>
                        </div>
                    </div>
                </div>
            </section>

            <hr />

            <!-- Pricing -->
            <!-- <section id="pricing">
                <div class="pricing-img-wrapper">
                    <h2 class="title">{{ $t("pricing.title") }}</h2>
                    <div class="img-container pricing-img">
                        <img
                            data-image="Home 1.jpg"
                            src="@/assets/images/home/Home 1 small.jpg"
                            title="Render of a dining area"
                            alt="Dining table in a living space with seating"
                            class="asyncImage"
                        />
                    </div>
                </div>
                <div class="options">
                    <h2 class="title init">{{ $t("pricing.title") }}</h2>

                    <div class="edit" v-if="getCookie('token')">
                        <Link
                            class="catch"
                            :title="$t('pricing.edit')"
                            path="/edit/prices"
                        />
                    </div>

                    <div class="option-prices">
                        <h3 class="subtitle">{{ $t("pricing.size-title") }}</h3>
                        <table>
                            <tr>
                                <td>20m² - 30m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "20m² - 30m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>30m² - 40m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "30m² - 40m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>40m² - 50m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "40m² - 50m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>50m² - 60m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "50m² - 60m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>60m² - 70m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "60m² - 70m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>70m² - 80m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "70m² - 80m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>80m² - 90m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "80m² - 90m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>90m² - 100m²</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p => p.title == "90m² - 100m²"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>100m²+</td>
                                <td>
                                    <a
                                        href="#contact"
                                        @click="scrollTo($event, '#contact')"
                                    >
                                        {{ $t("pricing.contact-us") }}
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="option-prices">
                        <h3 class="subtitle">
                            {{ $t("pricing.count-title") }}
                        </h3>
                        <table>
                            <tr>
                                <td>1</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "1" &&
                                                p.category == "count"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "2" &&
                                                p.category == "count"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "3" &&
                                                p.category == "count"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>3+</td>
                                <td>
                                    <a
                                        href="#contact"
                                        @click="scrollTo($event, '#contact')"
                                    >
                                        {{ $t("pricing.contact-us") }}
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="option-prices">
                        <h3 class="subtitle">{{ $t("pricing.env-title") }}</h3>
                        <table>
                            <tr>
                                <td>
                                    {{ $t("prices.env-no") }}
                                </td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "no" &&
                                                p.category == "env"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $t("prices.env-yes") }}
                                </td>
                                <td>
                                    <a
                                        href="#contact"
                                        @click="scrollTo($event, '#contact')"
                                    >
                                        {{ $t("pricing.contact-us") }}
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="option-prices">
                        <h3 class="subtitle">{{ $t("pricing.dwg-title") }}</h3>
                        <table>
                            <tr>
                                <td>
                                    {{ $t("prices.dwg-yes") }}
                                </td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "yes" &&
                                                p.category == "dwg"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $t("prices.dwg-no") }}
                                </td>
                                <td>
                                    <a
                                        href="#contact"
                                        @click="scrollTo($event, '#contact')"
                                    >
                                        {{ $t("pricing.contact-us") }}
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="option-prices">
                        <h3 class="subtitle">
                            {{ $t("pricing.revision-title") }}
                        </h3>
                        <table>
                            <tr>
                                <td>1</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "1" &&
                                                p.category == "revision"
                                        )[0].price
                                    }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                            <tr>
                                <td>2(+)</td>
                                <td v-if="prices">
                                    €{{
                                        prices.filter(
                                            p =>
                                                p.title == "2(+)" &&
                                                p.category == "revision"
                                        )[0].price
                                    }}/{{ $t("pricing.image") }}
                                </td>
                                <td v-else>...</td>
                            </tr>
                        </table>
                    </div>
                    <p>
                        {{ $t("pricing.disclaimer") }}
                    </p>
                </div>
                <div class="img-container side pricing-img">
                    <img
                        data-image="Home 1.jpg"
                        src="@/assets/images/home/Home 1 small.jpg"
                        title="Render of a dining area"
                        alt="Dining table in a living space with seating"
                        class="asyncImage"
                    />
                </div>
            </section> -->

            <section id="quote">
                <h2 class="title">{{ $t("pricing.quote.title") }}</h2>
                <Link
                    class="catch"
                    :title="$t('pricing.quote.catch')"
                    path="/personalized/quote"
                />
                <!-- <div class="bg"></div> -->
            </section>

            <hr />

            <!-- About me -->
            <section id="about">
                <div class="about-text-wrapper">
                    <h2 class="title">{{ $t("about.title") }}</h2>
                    <div class="text">
                        <p v-html="$t('about.text-1')"></p>
                        <p v-html="$t('about.text-2')"></p>
                        <Link
                            class="catch"
                            :title="$t('about.catch')"
                            :path="instagram"
                            :external="true"
                        />
                    </div>
                </div>
                <div class="img-container about-img">
                    <img
                        data-image="About.jpg"
                        src="@/assets/images/home/About small.jpg"
                        title="Julie Lejour"
                        alt="Image of Julie Lejour leaning against a wall"
                        class="asyncImage"
                    />
                </div>
            </section>
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
</template>

<script>
import Link from "@/components/Link";
import Footer from "@/components/layout/Footer";
import { getCookie } from "@/helpers";

export default {
    components: {
        Link,
        Footer
    },
    data() {
        return {
            instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM
        };
    },
    mounted() {
        // Async load images
        this.asyncLoadImages();

        // if (!this.prices) {
        //     this.$store.dispatch("getPrices");
        // }
    },
    computed: {
        // prices() {
        //     return this.$store.getters.allPrices;
        // }
    },
    methods: {
        asyncLoadImages() {
            const images = document.querySelectorAll(".asyncImage");
            const img = null;
            images.forEach(image => {
                const img = new Image();
                const origSrc = image.getAttribute("data-image");
                img.src = require(`@/assets/images/home/${origSrc}`);
                img.onload = () => {
                    image.classList.remove("asyncImage");
                    return image.nodeName === "IMG"
                        ? (image.src = require(`@/assets/images/home/${origSrc}`))
                        : null;
                };
            });
        },
        scrollTo(e, hash) {
            e.preventDefault();
            let el = document.getElementById(hash.split("#")[1]);
            const y = el.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
        },
        getCookie: getCookie
    },
    metaInfo() {
        return {
            titleTemplate: "Renders by Julie",
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Renders by Julie"
                },
                {
                    name: "description",
                    content:
                        "Wij zijn gespecialiseerd in het maken van architecturale binnen- en buiten renders voor uw ruimtes! Wij verzorgen uw 3D renders en 2D vloerplannen."
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "keywords",
                    content:
                        "render, renders, rendus, visual, visuals, visualisaties, model, beeld, beelden, interieur, binnen, interior, exterieur, buiten, exterior, 3D, 2D, ruimte, ruimtes, space, spaces sales plans, plans, plannen, verkoopsplannen, verkoopplannen, vloerplannen, grondplannen, plattegronden, floorplans, plans de vente, schema, opmaken, maken, visualiseren, ontwerpen, modelleren, visualize, visueel, 3D foto’s, 3D images, Julie, Lejour, Julie Lejour, Renders, Renders by Julie, rendersbyjulie, rendersbyjulie.be, portfolio, dwg, panorama, architecture, architectuur, Gent, Sint-Martens-Latem, De Pinte, Deinze, Merelbeke, Drongen, Oost-Vlaanderen"
                },
                {
                    name: "robots",
                    content: "index, follow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "revisit-after",
                    content: "30 days"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                },
                // Open Graph / Facebook
                { property: "og:locale", content: this.$root.$i18n.locale },
                { property: "og:type", content: "website" },
                { property: "og:url", content: "https://rendersbyjulie.be/" },
                { property: "og:title", content: "Renders by Julie" },
                {
                    property: "og:description",
                    content:
                        "Wij zijn gespecialiseerd in het maken van architecturale binnen- en buiten renders voor uw ruimtes! Wij verzorgen uw 3D renders en 2D vloerplannen."
                },
                {
                    property: "og:image",
                    content: require("@/assets/images/home/Home 1.jpg")
                },
                // Twitter
                { name: "twitter:card", content: "summary_large_image" },
                { name: "twitter:url", content: "https://rendersbyjulie.be/" },
                { name: "twitter:title", content: "Renders by Julie" },
                {
                    name: "twitter:description",
                    content:
                        "Wij zijn gespecialiseerd in het maken van architecturale binnen- en buiten renders voor uw ruimtes! Wij verzorgen uw 3D renders en 2D vloerplannen."
                },
                {
                    name: "twitter:image",
                    content: require("@/assets/images/home/Home 1.jpg")
                }
            ]
        };
    }
};
</script>

<style lang="scss">
section {
    min-height: 10rem;
    position: relative;

    max-width: 1312px;
    margin: 0 auto 10rem auto;
    padding-top: 10rem;

    .bg {
        width: calc(2 * 100vw);
        height: 100%;
        position: absolute;
        background: rgba(#f3eade, 0.4);
        margin-left: -100vw;
        z-index: -100;
        top: 0;

        @media (max-width: 400px) {
            display: none;
        }
    }

    // Transition on async load
    // img {
    //     transition: background-image 0.2s ease-out;
    // }

    // &:first-of-type {
    //     padding-top: 6rem;
    // }

    &.hero {
        padding: 10rem 0 0 0;
        // margin-bottom: 45rem;
        min-height: 70rem;
        margin: 0;

        // display: flex;
        // align-items: center;

        .quote {
            max-width: 372px;
            position: absolute;

            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);;
            right: 29rem;
            top: 28rem;
        }

        .img-container {
            position: absolute;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &.hero-img-1 {
                // width: 313px;
                // height: 600px;
                // top: 20rem;
                width: 300px;
                height: 525px;
                top: 21rem;
            }

            &.hero-img-2 {
                width: 424px;
                height: 370px;
                // top: calc(10rem - 313px + 350px);
            }

            &.hero-img-3 {
                // width: 535px;
                // height: 500px;
                // top: 10rem;

                // top: 65%;
                // left: 35%;
                // z-index: -1;
                // transform: translate(-50%, -50%);

                // width: 450px;
                // height: 275px;
                // top: 40rem;
                // right: 0;
                // z-index: -1;

                width: 500px;
                height: 350px;
                top: 48rem;
                left: 16rem;
                z-index: 1;
            }

            &.hero-img-4 {
                width: 570px;
                height: 325px;
                z-index: 1;
                right: 0;
                top: 9rem;
            }
        }

        .hero-img-2-wrapper {
            position: absolute;
            // right: 0;
            min-width: 424px;
            right: 0;
            top: 39rem;
            // z-index: 15;
            z-index: -1;

            .title {
                text-align: right;
                font-size: 48px;
                line-height: 41px;
                // line-height: 40px;
                // color: $maroon;
                font-family: "Nelphim", serif;
                font-weight: normal;
                margin-bottom: 12px;
                // font-size: 42px;
                // line-height: 34px;
                // text-transform: uppercase;

                span {
                    position: absolute;
                    top: 3rem;
                    z-index: 2;
                    color: $white;
                    left: -5px;
                }
            }
            @media (max-width: 750px) {
                .title {
                    font-size: 36px !important;
                    line-height: 28px !important;

                    span {
                        top: 2.5rem;
                    }
                }
            }
        }

        @media (max-width: 1150px) {
            .hero-img-2-wrapper {
                right: 0;
                left: unset;
            }

            .quote {
                left: 2%;
                top: 20rem;
                max-width: 40%;
                right: unset;
            }

            .hero-img-1 {
                display: none;
            }

            .hero-img-3 {
                right: unset !important;
                left: 0 !important;
                width: 424px !important;
                height: 300px !important;
                top: 50rem !important;
                // top: 45rem !important;
                // width: 100% !important;
                // height: 315px !important;
            }

            .hero-img-4 {
                width: 500px !important;
                height: 300px !important;
            }
        }

        @media (max-width: 900px) {
            .quote {
                left: 0;
                // max-width: 30%;
                right: unset;
            }
        }

        @media (max-width: 700px) {
            padding: 10rem 0 0 0;
            min-height: 60rem;
            // margin-bottom: 15rem;

            .hero-img-2-wrapper {
                position: relative;
                min-width: unset;
                top: -3rem;

                .img-container {
                    height: auto;
                    width: auto;
                }
            }

            .quote {
                position: relative;
                left: 0;
                right: 0;
                top: -6rem;
                max-width: unset;
                transform: unset;
                margin: 0 1rem;

                padding: 18px 56px 56px 56px;
            }

            .img-container {
                position: relative;
            }

            .hero-img-3 {
                width: 100% !important;
                height: auto !important;
                left: 0 !important;
                right: 0 !important;
                position: relative;
                top: 7rem !important;
            }

            .hero-img-4 {
                width: 70% !important;
                height: 210px !important;
                z-index: 1;
                margin: 0 auto;
                top: -25rem !important;
            }
        }
    }

    &#portfolio {
        // padding-bottom: 100rem;
        margin-bottom: 0;
        min-height: 147rem;

        .quote-1 {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            &.center-content {
                justify-content: center;
            }
            // min-height: 280px;
            // width: 280px;
            // position: absolute;

            // top: 45%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // padding: 24px 35px 56px 56px;

            min-height: 280px;
            width: 280px;
            position: absolute;
            padding: 24px 35px 56px 56px;
            left: 25rem;
            top: 27rem;

            ul {
                font-weight: bold;
                font-size: 24px;

                a {
                    font-family: "acumin-pro", serif !important;

                    transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

                    &:hover {
                        padding-left: 0.5rem;
                        text-decoration: underline;
                    }
                }
            }
        }

        .img-container {
            position: absolute;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &.portfolio-img-1 {
                // width: 535px;
                // height: 500px;

                width: 675px;
                height: 415px;
                right: 0;
                top: 20rem;
            }

            &.portfolio-img-2 {
                // width: 400px;
                // height: 500px;
                // right: 0;
                // top: -15rem;

                width: 350px;
                height: 440px;
                // right: 0;
                // top: 5rem;
            }

            &.portfolio-img-3 {
                width: 90%;
                // height: 600px;

                top: 43rem;
                position: relative;
                margin: 0 auto;
                z-index: -1;
                transform: translate(-50%, -50%);
            }

            &.portfolio-img-4 {
                // height: 640px;
                // width: 940px;
                // top: 80rem;
                // right: 0;
                // z-index: -2;
                // opacity: 0.8;

                height: 850px;
                width: 550px;
                top: 95rem;
                z-index: -2;
                // opacity: 0.8;
                right: 5rem;
            }

            &.portfolio-img-5 {
                height: 250px;
                width: 650px;
                // height: 200px;
                // width: 550px;
                top: 125rem;
                z-index: -2;

                @media (max-width: 1315px) {
                    display: none;
                }
            }
        }

        .quote-2 {
            // max-width: 560px;
            // height: 120px;
            // position: absolute;

            // top: 90rem;
            // left: 10rem;

            // padding: 24px 35px 56px 56px;
            max-width: 375px;
            height: 175px;
            position: absolute;
            top: 100rem;
            left: 5rem;

            padding: 36px 24px 36px 56px;
        }

        .portfolio-img-1-wrapper {
            top: 6rem;
            position: absolute;
            z-index: 1;
            // position: absolute;
            // top: -10rem;
            // min-width: 424px;

            .title {
                font-family: "Nelphim", serif;
                font-weight: normal;
                font-size: 56px;
                line-height: 47px;
                margin-bottom: 12px;

                span {
                    position: absolute;
                    top: 3rem;
                    z-index: 2;
                    color: $white;
                }
            }
        }

        @media (max-width: 1150px) {
            min-height: 136rem;
            .portfolio-img-1 {
                top: 14rem !important;
                right: unset !important;
            }

            .portfolio-img-2 {
                display: none;
            }

            .quote-1 {
                right: 0;
                left: unset;

                top: 16rem;
                width: 30%;
            }

            .quote-2 {
                width: 40%;
                top: 95rem !important;
            }

            .portfolio-img-4 {
                top: 85rem !important;
            }
        }

        @media (max-width: 900px) {
            min-height: 125rem;
            // padding-bottom: 85rem;

            .portfolio-img-1 {
                top: unset !important;
                right: 0 !important;
            }

            .portfolio-img-1-wrapper {
                width: 100%;

                .img-container {
                    width: 100%;
                }
            }

            .portfolio-img-3 {
                width: 100% !important;
                top: 40rem !important;
            }

            .quote-1 {
                top: 40rem;
                left: 13rem;
                position: relative;
                left: 0;
                right: 0;
                top: 1rem;
                max-width: unset;
                width: auto;
                transform: unset;
                padding: 18px 56px 56px 56px;
                min-height: 60px !important;
                margin: 0 1rem;

                ul,
                br {
                    display: none;
                }
            }

            .portfolio-img-4 {
                left: 0;
                transform: rotate(270deg) translateY(-175px);
            }

            .quote-2 {
                padding: 18px 56px 56px 56px;
                max-width: 375px;
                height: 215px;
                position: absolute;
                // top: 95rem;
                right: 0;
                left: unset;
                top: 85rem !important;
            }

            .portfolio-img-4 {
                position: absolute;
                left: -15rem;
            }
        }

        @media (max-width: 700px) {
            min-height: 108rem;
            // height: 80rem;
            padding-top: 6rem;
            // padding-bottom: 30rem;

            margin-bottom: 0rem;

            .quote-1 {
                top: 10rem;
            }

            .portfolio-img-1-wrapper {
            top: 0;
            position: relative;
                .title {
                    line-height: 39px !important;
                }
                .img-container {
                    max-height: 20rem;
                }
            }

            .portfolio-img-2 {
                display: none;
            }

            .portfolio-img-3 {
                height: auto !important;
                width: 100% !important;
                top: 35rem !important;
            }

            .portfolio-img-4 {
                top: 65rem !important;
                position: absolute;
                left: 0;
                transform: rotate(270deg) translateY(-400px);
            }

            .quote-2 {
                top: 67rem !important;
                width: 70%;
            }
        }
    }

    &#workflow {
        display: flex;
        align-items: flex-start;
        height: 42rem;

        .catch {
            margin-top: 1rem;
            color: $maroon;
        }

        .img-container {
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;

                @media (max-width: 1000px) {
                    object-position: 0px 55%;
                }
            }

            &.workflow-img {
                z-index: -1;
                height: 100%;
            }
        }

        .underline {
            text-decoration: underline;

            &:hover {
                color: $maroon;
            }
        }

        .workflow-img-wrapper {
            min-width: 50%;
            height: 100%;

            @media (max-width: 1312px) {
                min-width: 20%;
            }

            @media (max-width: 1000px) {
                min-width: unset;
                height: 15rem;
                margin-bottom: 3rem;
                min-width: 100%;

                img {
                    object-position: 0px 65%;
                }
            }

            .title {
                font-size: 56px;
                line-height: 47px;
                font-family: "Nelphim", serif;
                font-weight: normal;
                position: relative;
                top: -12px;

                span {
                    position: absolute;
                    top: 3rem;
                    z-index: 2;
                    color: $white;
                }
            }
        }

        .text {
            top: 3rem;
            position: relative;

            .numbers {
                position: absolute;
                left: -4rem;
                color: #fff;
                font-size: 72px;

                font-family: "Nelphim", serif;
                font-weight: normal;

                * {
                    // margin: 0 0 1.7rem 0;
                    margin: 0 0 2.9rem 0;
                }

                @media (max-width: 1000px) {
                    visibility: hidden;
                }
            }

            .step {
                margin-bottom: 2rem;
                display: flex;
                align-items: flex-start;

                div {
                    position: relative;

                    p {
                        margin: 0;
                        font-family: "Titillium Web", sans-serif;
                    }

                    h3 {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                }

                .step-number {
                    visibility: hidden;
                    left: -4rem;
                    height: 0;
                    width: 0;
                    color: $white;
                    font-size: 72px;
                    // font-weight: bold;
                    margin-right: 2rem;

                    top: 1rem;
                    font-family: "Nelphim", serif;
                    font-weight: normal;
                }

                @media (max-width: 1000px) {
                    margin-bottom: 3rem;
                    flex-direction: column;

                    div {
                        margin-left: 4rem;
                    }

                    .step-number {
                        color: $black;
                        visibility: visible;
                    }
                }
            }
        }

        @media (max-width: 750px) {
            .title {
                line-height: 40px !important;
            }
        }

        @media (max-width: 700px) {
            padding-top: 6rem;
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            height: auto;
            min-height: unset;
        }
    }

    &#pricing {
        display: flex;
        align-items: flex-start;
        height: 73rem;
        justify-content: space-between;

        .title {
            font-family: "Nelphim", serif;
            font-weight: normal;
            position: relative;
            z-index: 100;
            font-size: 56px;
            line-height: 47px;
            margin-bottom: 12px;
        }

        .subtitle {
            font-size: 24px;
        }

        .edit {
            margin: 2rem 0;
        }

        .options {
            width: 55%;

            h3 {
                margin: 0 0 1rem 0;
            }

            .option-prices {
                // background-color: $washed-red;
                margin: 3rem 0;
            }

            table {
                border-spacing: 0;
                width: 100%;

                tr:nth-child(odd) {
                    background-color: $washed-red;
                }

                td {
                    font-family: "Titillium Web", sans-serif;
                    padding: 0.25rem 0.5rem;
                }

                td:last-child {
                    text-align: right;
                }

                a {
                    text-decoration: underline;
                    transition: color 0.1s linear;

                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        .img-container {
            overflow: hidden;
            width: 40%;
            height: 100%;

            &.side {
                top: 3rem;
                position: relative;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &.side img {
                // transform: scaleX(-1);
                // object-position: 100% 0px;
                object-position: 90% 0px;
            }

            &.pricing-img {
                z-index: -1;
                height: 100%;
            }

            @media (max-width: 1000px) {
                &.side {
                    display: none;
                }
            }
        }

        .pricing-img-wrapper {
            display: none;
            width: 100%;
            min-width: unset;
            height: 15rem;
            margin-bottom: 3rem;

            .img-container {
                width: 100%;

                img {
                    object-position: 0px 90%;
                }
            }

            @media (max-width: 1000px) {
                display: block;
            }
        }

        @media (max-width: 700px) {
            padding-top: 6rem;
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            height: auto;
            min-height: unset;

            .init {
                display: none;
            }

            .options {
                width: 100%;
            }
        }
    }

    &#quote {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0;
        padding-bottom: 10rem;
        min-height: unset;

        .title {
            font-family: "Nelphim", serif;
            font-weight: normal;
            font-size: 56px;
        }

        @media (max-width: 750px) {
            .title {
                font-size: 42px !important;
                line-height: 48px !important;
            }
        }

        @media (max-width: 450px) {
            .title {
                font-size: 32px !important;
                line-height: 42px !important;
            }
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            justify-content: flex-end;
            align-items: end;

            .title {
                margin-bottom: 1rem;
            }
        }
    }

    &#about {
        display: flex;
        align-items: flex-start;

        height: 50rem;

        margin-bottom: 20rem;

        .title {
            font-family: "Nelphim", serif;
            font-weight: normal;
            font-size: 56px;
            line-height: 59px;
        }

        .about-text-wrapper {
            top: 56px;
            position: relative;
            width: 45%;
            padding: 56px;

            box-shadow: 10px 20px 113px 0px #1f1f1f1e;
            background: $white;

            h2 {
                position: absolute;
                top: -65px;
                left: 0;
            }

            p {
                font-size: 18px;
                line-height: 42px;
                margin-bottom: 56px;
                font-family: "Titillium Web", sans-serif;
            }

            a.catch {
                position: absolute !important;
                right: 56px;
                bottom: 30px;
            }
        }

        .about-img {
            width: 50%;
            position: absolute;
            right: 0;
            height: 100%;
        }

        .img-container {
            overflow: hidden;
            z-index: -1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;

                @media (max-width: 1000px) {
                    object-position: 0px 55%;
                }
            }
        }

        @media (max-width: 750px) {
            .title {
                line-height: 66px !important;
            }
        }

        @media (max-width: 700px) {
            padding-top: 6rem;
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            justify-content: flex-end;
            align-items: end;

            height: auto;
            margin-bottom: 10rem;

            & > div {
                width: 100% !important;
            }

            .img-container {
                position: relative;
            }
            .about-text-wrapper {
                margin-bottom: calc(3rem + 56px);
                padding: 0;

                .text {
                    padding: 56px;
                }
            }
        }
    }
}
</style>
