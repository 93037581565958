<template>
    <header>
        <nav class="container">
            <h2 class="hidden">{{ $t("nav.title") }}</h2>
            <a
                href="#home"
                v-if="$route.name === 'Home'"
                class="logo"
                id="logo"
            >
                <img
                    src="@/assets/images/logo.svg"
                    title="Renders by Julie logo"
                    alt="Red and maroon logo image for Renders by Julie"
                />
            </a>
            <router-link v-else to="/" class="logo" id="logo">
                <img
                    src="@/assets/images/logo.svg"
                    title="Renders by Julie logo"
                    alt="Red and maroon logo image for Renders by Julie"
                />
            </router-link>
            <div class="menu">
                <scrollactive active-class="router-link-active" :offset="0">
                    <h3 class="hidden">{{ $t("nav.desktop-menu") }}</h3>
                    <ul>
                        <li>
                            <a
                                href="#home"
                                v-if="$route.name === 'Home'"
                                data-section-selector=".hero"
                                class="scrollactive-item router-link-active"
                            >
                                {{ $t("nav.home") }}
                            </a>
                            <router-link v-else to="/" exact>
                                {{ $t("nav.home") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/portfolio">
                                {{ $t("nav.portfolio") }}
                            </router-link>
                        </li>
                        <li>
                            <a
                                v-if="$route.name === 'Home'"
                                href="#workflow"
                                class="scrollactive-item"
                            >
                                {{ $t("nav.workflow") }}
                            </a>
                            <router-link v-else to="/#workflow">
                                {{ $t("nav.workflow") }}
                            </router-link>
                        </li>
                        <li>
                            <a
                                v-if="$route.name === 'Home'"
                                href="#quote"
                                class="scrollactive-item"
                            >
                                {{ $t("nav.pricing") }}
                            </a>
                            <router-link v-else to="/#quote">
                                {{ $t("nav.pricing") }}
                            </router-link>
                        </li>
                        <li>
                            <a
                                v-if="$route.name === 'Home'"
                                href="#about"
                                class="scrollactive-item"
                            >
                                {{ $t("nav.about") }}
                            </a>
                            <router-link v-else to="/#about">
                                {{ $t("nav.about") }}
                            </router-link>
                        </li>
                        <li>
                            <select
                                v-model="$root.$i18n.locale"
                                @change="setLanguage()"
                            >
                                <option value="nl" selected>nl</option>
                                <option value="en">en</option>
                            </select>
                        </li>
                    </ul>
                    <a
                        href="#contact"
                        class="catch btn dark-maroon-bg scrollactive-item"
                        @click="getCookie('token') ? logout($event) : null"
                    >
                        <span class="inner">
                            {{
                                getCookie("token")
                                    ? $t("nav.log-out")
                                    : $t("nav.get-in-touch")
                            }}
                            <i class="icon arrow arrow-white"></i>
                        </span>
                    </a>
                </scrollactive>

                <div @click="openMenu()" class="burger">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { getCookie } from "@/helpers";

export default {
    created() {
        this.setHeaderAnimation();
    },
    mounted() {
        this.setHeaderAnimation();
    },
    updated() {
        this.setHeaderAnimation();
    },
    methods: {
        getCookie: getCookie,
        setHeaderAnimation() {
            if (window.innerWidth >= 700) {
                window.onscroll = function() {
                    let logo = document.getElementById("logo");
                    if (
                        document.body.scrollTop > 50 ||
                        document.documentElement.scrollTop > 50
                    ) {
                        if (logo !== null) {
                            logo.style.height = "100%";
                            logo.style.top = 0;
                            logo.style.left = 0;
                            logo.style.padding = "24px 1rem";
                        }
                    } else {
                        if (logo !== null) {
                            logo.style.height = "125%";
                            logo.style.top = "96px";
                            logo.style.left = "1rem";
                            logo.style.padding = "0";
                        }
                    }
                };
            }
            window.onresize = function() {
                if (window.innerWidth >= 700) {
                    window.onscroll = function() {
                        let logo = document.getElementById("logo");
                        if (
                            document.body.scrollTop > 50 ||
                            document.documentElement.scrollTop > 50
                        ) {
                            if (logo !== null) {
                                logo.style.height = "100%";
                                logo.style.top = 0;
                                logo.style.left = 0;
                                logo.style.padding = "12px 1rem";
                            }
                        } else {
                            if (logo !== null) {
                                logo.style.height = "125%";
                                logo.style.top = "96px";
                                logo.style.left = "1rem";
                                logo.style.padding = "0";
                            }
                        }
                    };
                } else {
                    window.onscroll = null;
                }
            };
            if (window.innerWidth < 900) {
                window.onscroll = null;
            }
        },
        openMenu() {
            document
                .querySelector(".burger-menu")
                .classList.add("animate-menu");
            document.querySelector("body").style.overflowY = "hidden";
        },
        setLanguage() {
            localStorage.setItem("lang", this.$root.$i18n.locale);
        },
        logout(event) {
            event.preventDefault();
            this.$emit("loading", this.$t("logout.text"));
            window.location.href = "/logout";
        }
    }
};
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    pointer-events: none;

    *:not(nav) {
        pointer-events: auto;
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 6rem;
        position: relative;

        @media (max-width: 900px) {
            height: 4rem;
        }

        .logo {
            position: absolute;
            box-sizing: border-box;

            height: 125%;
            max-width: 400px;
            min-width: 125px;
            top: 96px;
            left: 1rem;

            transition: all 0.1s linear;

            @media (max-width: 700px) {
                height: 100% !important;
                padding: 12px 1rem !important;
                left: 0 !important;
                top: 0 !important;
                max-width: 180px !important;
                width: 125px !important;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // align-items: center;
        height: 6rem;

        @media (max-width: 900px) {
            height: 4rem;
        }

        * {
            letter-spacing: 2px;
            font-size: 18px;
        }

        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            height: 6rem;

            li {
                display: inline-block;
                height: 100%;
                margin: 0 2rem;
                display: flex;
                align-items: center;

                select {
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    margin-left: -3px;
                    padding-right: 0.5rem;

                    font-family: "Titillium Web", sans-serif;

                    &:focus {
                        // outline: none;
                        outline: 2px solid $black;
                    }
                }

                a {
                    // text-shadow: 0 0 0px #fff;
                    color: $black;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    transition: text-shadow 0.1s linear;

                    .hover-bar {
                        width: 4px;
                        height: 0;
                        background-color: $blue;
                        position: absolute;
                        z-index: -1;
                        left: -8px;
                        bottom: 0;
                        transition: height 0.1s ease-out, width 0.05s ease-out;
                    }

                    .underline {
                        bottom: -0.5rem;
                        left: 0;
                        height: 3px;
                    }

                    &.router-link-active {
                        font-weight: bold;

                        .underline {
                            width: 100%;
                        }
                    }

                    &:not(.router-link-active):hover {
                        text-shadow: 0px 0px 1px #1f1f1f;
                    }
                }
            }

            @media (max-width: 1320px) {
                display: none;
            }
        }

        .catch {
            height: 3.8rem;
            display: flex;
            align-items: flex-end;
            padding-bottom: 1rem;
            margin: 0;
            width: 212px;

            .inner {
                display: flex;
                align-items: center;
            }

            .icon {
                width: 34px;
                height: 12px;
                margin-left: 0.5rem;
                margin-top: 1px;
                transition: margin 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            }

            &:hover .icon {
                margin-left: 1rem;
            }

            @media (max-width: 1320px) {
                background: none;
                align-items: center;
                color: $black;

                .icon {
                    background-image: url("../../assets/icons/arrow.svg") !important;
                }
            }

            @media (max-width: 700px) {
                transform: scale(0.8);
                width: 175px;
                padding-bottom: 0;
                position: relative;
                right: -1rem;
            }
        }
    }

    .burger {
        display: none;
        place-items: center;
        position: relative;
        cursor: pointer;

        margin: 1rem;
        width: 30px;
        height: 24px;

        .bar {
            background-color: $black;
            width: 30px;
            height: 2px;
            position: absolute;

            &:first-of-type {
                transform: translateY(10px);
                width: 25px;
                right: 0;
            }

            &:last-of-type {
                transform: translateY(-10px);
            }
        }

        @media (max-width: 1320px) {
            display: grid;
        }
    }
}
</style>
