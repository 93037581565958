function getLang(vm) {
	const { lang } = vm.$options;
	if (lang) {
		return typeof lang === "function" ? lang.call(vm) : lang;
	}
}
export default {
	created() {
		const lang = getLang(this);
		if (lang) {
			document.documentElement.lang = lang;
		}
	},
};
