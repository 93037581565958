<template>
    <div id="cookie-consent">
        <p v-html="$t('general.cookie')"></p>
        <div>
            <a href="#!" class="agree" @click="acceptCookies($event)">
                <span class="inner">
                    OK
                </span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        acceptCookies(e) {
            e.preventDefault();
            localStorage.setItem("cookie-consent", true);
            document.getElementById("cookie-consent").style.maxHeight = 0;
        }
    }
};
</script>

<style lang="scss">
#cookie-consent {
    z-index: 101;
    background-color: darken($washed-red, 5%);
    position: fixed;
    margin: 0 auto;
    bottom: 0;
    right: 0;
    left: 0;

    overflow: hidden;

    max-height: 25rem;
    transition: max-height 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    flex-direction: row;
    align-items: center;
    display: grid;
    grid-template: 100% / 3fr 1fr;
    grid-gap: 2rem;

    > * {
        padding: 0 3rem;
    }

    p a {
        text-decoration: underline;
        transition: color 0.1s ease-out;

        &:hover {
            color: $red;
        }
    }

    div {
        display: flex;
        justify-content: flex-end;

        a.agree {
            position: relative;
            font-weight: bold;
            transition: letter-spacing 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

            &:hover {
                letter-spacing: 0.2em;
            }
        }
    }

    @media (max-width: 600px) {
        grid-template: fit-content(1ch) fit-content(1ch) / 100%;
        grid-gap: 0;

        > * {
            padding: 0rem 1rem;
            margin-bottom: 1rem;
            font-size: 14px !important;
        }
    }
}
</style>
