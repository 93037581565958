import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { clearCookie, getCookie, logOut } from "../helpers/index";

Vue.use(VueRouter);

const scrollBehavior = function(to, from, savedPosition) {
    if (to.hash) {
        const el = window.location.href.split("#")[1];
        if (el.length) {
            document.getElementById(el).scrollIntoView({ behavior: "smooth" });
        }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        document.getElementById("app").scrollIntoView();
    }
};

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        props: true,
        component: () => import("../views/portfolio/Portfolio.vue")
    },
    {
        path: "/portfolio/:id/:key",
        name: "Project",
        component: () => import("../views/portfolio/Project.vue")
    },
    {
        path: "/portfolio/add/project/:category?",
        name: "AddProject",
        props: true,
        component: () => import("../views/portfolio/Add.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("token")) {
                next({ name: "Login" });
            }
            next();
        }
    },
    {
        path: "/portfolio/edit/project/:category?",
        name: "EditProject",
        props: true,
        component: () => import("../views/portfolio/Edit.vue"),
        beforeEnter: (to, from, next) => {
            if (!getCookie("token")) {
                next({ name: "Login" });
            }
            next();
        }
    },
    {
        path: "/personalized/quote",
        name: "Quote",
        component: () => import("../views/Quote.vue")
    },
    // {
    //     path: "/edit/prices",
    //     name: "Prices",
    //     props: true,
    //     component: () => import("../views/auth/Prices.vue"),
    //     beforeEnter: (to, from, next) => {
    //         if (!getCookie("token")) {
    //             next({ name: "Login" });
    //         }
    //         next();
    //     }
    // },
    {
        path: "/privacy",
        name: "Privacy",
        props: true,
        component: () => import("../views/Privacy.vue")
    },

    // Authorization routes
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/auth/Login.vue"),
        beforeEnter: (to, from, next) => {
            if (getCookie("token")) {
                clearCookie("token");
            }
            next();
        }
    },
    {
        path: "/logout",
        name: "Logout",
        beforeEnter: (to, from, next) => {
            if (!getCookie("token")) {
                next({ name: "404" });
            } else {
                logOut(next);
            }
        }
    },

    // Error routes
    {
        path: "/error",
        name: "Error",
        component: () => import("../views/error/Error.vue")
    },
    {
        path: "*",
        name: "404",
        component: () => import("../views/error/NotFound.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior,
    routes,
    duplicateNavigationPolicy: "reload"
});

export default router;
