<template>
    <div id="app">
        <h1 class="hidden">{{ company }}</h1>
        <Header
            v-if="$route.name !== '404' && $route.name !== 'Quote'"
            @loading="setLoading"
            @done="
                loading_message = null;
                loading = false;
            "
        />
        <BurgerMenu
            @loading="setLoading"
            @done="
                loading_message = null;
                loading = false;
            "
        />
        <Loader v-if="loading" :message="loading_message" />
        <router-view
            @loading="setLoading"
            @done="
                loading_message = null;
                loading = false;
            "
        />
        <CookieConsent />
    </div>
</template>

<script>
import Header from "@/components/layout/Header";
import CookieConsent from "@/components/CookieConsent";
import BurgerMenu from "@/components/BurgerMenu";
import Loader from "@/components/layout/Loader";

export default {
    components: {
        Header,
        CookieConsent,
        BurgerMenu,
        Loader
    },
    data() {
        return {
            company: process.env.VUE_APP_COMPANY_NAME,
            loading_message: null,
            loading: false
        };
    },
    mounted() {
        if (localStorage.getItem("cookie-consent") == "true") {
            document.getElementById("cookie-consent").style.display = "none";
        }
    },
    methods: {
        setLoading(message) {
            if (message) {
                this.loading_message = message;
            }
            this.loading = true;
        }
    },
    metaInfo() {
        return {
            title: "Website",
            titleTemplate: `%s - ${this.company}`
        };
    }
};
</script>

<style lang="scss">
// Nephilm
@font-face {
    font-family: "Nelphim";
    src: url("assets/fonts/nelphim.otf") format("opentype");
}

html,
body {
    margin: 0;
    padding: 0;
    // scroll-behavior: smooth;
    height: 100%;
}

body {
    letter-spacing: 0.1em;
    overflow-x: hidden;
}

@media (max-width: 750px) {
    h1.title {
        font-size: 36px !important;
    }

    h2 {
        font-size: 48px !important;
        line-height: 48px !important;
    }

    .trema {
        grid-gap: 10px !important;

        > div {
            width: 10px !important;
            height: 10px !important;
        }
    }
}

@media (max-width: 500px) {
    p:not(.remark) {
        font-size: 18px !important;
    }

    .text-2 ul {
        font-size: 18px !important;
    }

    footer ul {
        font-size: 16px !important;
    }

    footer select {
        font-size: 16px !important;
    }

    h1.title {
        font-size: 30px !important;
    }

    .trema {
        grid-gap: 8px !important;

        > div {
            width: 8px !important;
            height: 8px !important;
        }
    }
}

.quote {
    padding: 56px 72px;
    background: $white;
    box-shadow: 10px 20px 113px 0px #1f1f1f1e;
    position: relative;
    z-index: 10;

    p {
        font-family: "Titillium Web", sans-serif !important;
        font-size: 18px;
        position: relative;
        line-height: 32px;

        &::before {
            content: "\201C";
            position: absolute;
            left: -18px;
            font-size: 24px;
        }

        &::after {
            content: "\201D";
            font-size: 24px;
            margin-left: 0.3rem;
        }
    }

    a.catch {
        position: absolute !important;
        right: 30px;
        bottom: 30px;
    }

    @media (max-width: 900px) {
        padding: 56px 56px;
    }
}

::selection {
    color: white;
    background: lighten($red, 20%);
}

#app {
    font-family: "acumin-pro", serif, "Titillium Web", sans-serif;
    color: $black;
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    margin: 0 auto;
    max-width: 1312px;
}

.content {
    flex: 1 0 auto;

    main {
        width: 95%;
    }

    .header {
        margin-bottom: 3.5rem;

        .title {
            text-align: right;
            font-family: "Nelphim", serif;
            font-weight: normal;
        }

        @media (max-width: 700px) {
            padding-top: 0;

            .title {
                text-align: left;
            }
        }
    }

    &:not(.home) {
        margin-top: 10rem;
    }

    @media (min-width: 1312px) {
        main {
            width: 100%;
        }
    }
}

.container {
    max-width: 1312px;
    margin: 0 auto;
    width: 100%;
}

h1,
h2 {
    margin: 0;
    font-weight: bold;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: 0px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: $black;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
}

.text-right {
    text-align: right;
}

.work.content .title,
.project.content .title,
.login.content .title {
    text-align: right;
}

.main-link {
    display: grid;
    place-items: flex-end;
}

.underline {
    height: 4px;
    // width: 0;
    // background-color: $black;
    // transition: width 0.2s ease-out;
    // position: absolute;
}

.hidden {
    display: none;
}

.router-link-active {
    font-weight: bold;
}

.icon {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-size: cover;
    vertical-align: middle;

    &.arrow {
        background-image: url("assets/icons/arrow.svg");

        &.down {
            background-image: url("assets/icons/arrow-down.svg");
        }

        &.arrow-white {
            background-image: url("assets/icons/arrow-white.svg");
        }
    }

    &.plus {
        background-image: url("assets/icons/plus.svg");
    }

    &.cancel {
        background-image: url("assets/icons/cancel.svg");
    }

    &.facebook {
        background-image: url("assets/icons/facebook-black.png");
    }

    &.instagram {
        background-image: url("assets/icons/instagram-black.png");
    }

    &.linkedin {
        background-image: url("assets/icons/linkedin-black.png");
    }
}

.error-remark {
    color: $red;
    font-size: 18px;
}

@media (max-width: 600px) {
    .tinybox__content__control {
        width: 2em !important;
    }
}

.tinybox__content__control--next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='2 -2 28 36' width='40' height='60' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'%3E%3Cpath d='M12 30 L24 16 12 2' /%3E%3C/svg%3E") !important;
}

.tinybox__content__control--prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='2 -2 28 36' width='40' height='60' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'%3E%3Cpath d='M20 30 L8 16 20 2' /%3E%3C/svg%3E") !important;
}

.tinybox__content__control--close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 46 46' width='40' height='40' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'%3E%3Cpath d='M2 30 L30 2 M30 30 L2 2' /%3E%3C/svg%3E") !important;
}

.red-bg {
    background-color: lighten($red, 20%);
}

.maroon-bg {
    color: white;
    background-color: lighten($maroon, 0%);
    color: $white;
}

.dark-maroon-bg {
    color: white;
    background-color: darken($maroon, 7%);
    color: $white;
}

.grey-bg {
    background-color: $grey;
}

.blue-bg {
    background-color: $blue;
}

.baby-blue-bg {
    background-color: $baby-blue;
}

.red {
    color: $red;
}

.yellow {
    background-color: $yellow;
}

.purple {
    background-color: $purple;

    &-text {
        color: $purple;
    }
}

.cyan {
    background-color: $cyan;
}

.blue {
    background-color: $blue;
}

.baby-blue {
    background-color: $blue;
}

.black {
    background-color: $black;
}

.grey {
    background-color: $light-grey;
}

.white {
    background-color: white;
}

.asterix {
    color: $red;
}

.shape-container {
    z-index: -1;
}

.shape {
    position: absolute;
}

@media (max-width: 1150px) {
    .rellax {
        transform: translate3d(0, 0, 0) !important;
    }
}

hr {
    border: none;
    border-top: 1px solid;
    border-color: $black;
    width: 100%;
    margin: 0 auto;
}

.remark {
    font-size: 14px;
    font-family: "Titillium Web", sans-serif;
    color: $grey;

    a {
        transition: color 0.1s ease-out;

        &:hover {
            color: $blue;
            text-decoration: underline;
        }
    }
}

.btn {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: normal;

    height: 32px;
    // width: 164px;
    width: 180px;

    padding: 0;
    margin: 0 0 0 2rem;

    font-size: 18px;
    transition: all 0.1s linear;

    &.btn-yellow {
        background-color: $yellow;
    }

    &:hover {
        color: white;
        background-color: $black;
    }

    &.btn-baby-blue {
        background-color: $baby-blue;
        color: $black;
    }

    @media (max-width: 1240px) {
        margin: 0 2rem;
    }

    @media (max-width: 900px) {
        margin: 0 1rem;
    }
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button {
    border: none;
    height: 32px;
    background-color: lighten($red, 20%);
    color: $white;
    font-size: 18px;
    cursor: pointer;
    width: auto;
    min-width: 240px;
    padding: 0 3rem;
    min-height: 3rem;
    transition: all 0.1s ease-out;

    &:hover {
        color: white;
        background-color: $black;
    }

    @media (max-width: 700px) {
        width: 100%;
    }
}

// img {
//     max-width: 100%;
// }

.form-element {
    margin: 2rem 0;
}

#thumbnail-grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
    }
    .thumbnail-container {
        display: flex;
        position: relative;
        overflow: hidden;

        img {
            max-width: 100%;
            object-fit: cover;
            cursor: pointer;
        }

        .icon {
            position: absolute;
            right: 0;
            margin: 0.5rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

            &:hover {
                transform: scale(1.1) rotate(90deg);
            }
        }

        & .inner-thumbnail-container:not(.selected-thumbnail):hover::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 45px 45px 0 0;
            border-color: rgba(31, 31, 31, 0.5) transparent transparent
                transparent;
            position: absolute;
            left: 0;
            top: 0;
        }

        &.selected-thumbnail::before,
        & .inner-thumbnail-container:not(.selected-thumbnail):active::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 45px 45px 0 0;
            border-color: $black transparent transparent transparent;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.checkbox-container {
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $washed-red;
    border: 2px solid $black;
    transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: $grey;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: $blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 8px;
    top: 2px;
    width: 6px;
    height: 13px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.invisible {
    visibility: hidden;
}

button[type="submit"] {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-out;

    &.disabled {
        background-color: $light-grey;
        pointer-events: none;
    }
}

.loading {
    width: 4rem;
    height: 1rem;
    position: absolute;
    z-index: 100;

    .square {
        position: absolute;
        width: 1rem;
        height: 1rem;
        animation: loading 1s ease-out infinite forwards;
        left: 0;
        top: 0;
    }

    @keyframes loading {
        0% {
            left: 0;
            top: 0;
        }

        50% {
            left: calc(100% - 1rem);
            top: 0;
        }

        100% {
            left: 0;
            top: 0;
        }
    }
}
</style>
